import React from 'react'
import {Link} from "react-router-dom";
import {
    FaInstagram,
    FaDribbble,
    FaXTwitter,
    FaYoutube,
  } from "react-icons/fa6";

function Footer() {
    const socialLinks = [
        { label: "YouTube", icon: FaYoutube, url: "/"},
        { label: "Instagram", icon: FaInstagram , url: "/"},
        { label: "Twitter", icon: FaXTwitter , url: "/"},
      ];
    
      const links = [
        [
          { label: "General", key: "header-1", url: "/"},
          { label: "Terms of service", key: "item-2-2", url: "/terms"},
          { label: "Privacy policy", key: "item-2-4", url: "/privacy"},
        ],
        [
          { label: "Links", key: "header-2", url: "/"},
          { label: "Flograppling", key: "item-2-1", url: "https://www.flograppling.com"},
          { label: "BJJNews", key: "item-2-1", url: "https://www.bjjee.com/"},
        ],
      ];

    return (
        <section className="relative bg-white ">
            

            <div className="app min-h-screen flex items-end justify-center font-poppins">
                <div className="py-16 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 bg-gray-100 text-white w-full p-4 relative">
                    <div className="  ">
                    <div className="footer-img flex items-center">
                        <img src={"https://i.imgur.com/520zDfd.png"} alt="" className="w-16 h-auto" />
                        <span className="text-3xl font-bold pl-2 text-black">
                            TapeToes
                        </span>
                    </div>
                    <div className="infos text-gray-400">
                        <span>Copyright © </span>
                        <span>All rights reserved</span>
                    </div>
                    <div className="footer-icons flex items-center space-x-3">
                        {socialLinks.map((socialLink, index) => {
                        const Icon = socialLink.icon;
                        return (
                            <Link to={socialLink.url}>
                                <Icon
                                key={`social-${index}`}
                                className="w-14 h-14 p-2 rounded-full bg-gray-700 hover:bg-white hover:text-green-700 cursor-pointer"
                                />
                            </Link>
                        );
                        })}
                    </div>
                    </div>
                    <div className="mx-2 grid w-full py-5 sm:py-0 grid-cols-2 ">
                    {links.map((col, index) => {
                        return (
                        <ul className={`col col-${index + 1}`} key={`col-${index}`}>
                            {col.map((link, index) => {
                            return (
                                <Link to={link.url}>
                                    <li
                                        key={`link-${col}-${index}`}
                                        className={`text-gray-400 cursor-pointer ${
                                            link.key === "header-1" || link.key === "header-2"
                                            ? "text-2xl text-black"
                                            : ""
                                        }`}
                                        >
                                        {link.label}
                                    </li>
                                </Link>
                            );
                            })}
                        </ul>
                        );
                    })}
                    </div>
                    <div className="footer-form flex flex-col  ">
                    <label className="text-lg font-semibold text-black">
                        Stay up to date
                    </label>
                    <input
                        type="email"
                        placeholder="Subscribe to our email"
                        className="mt-2 w-full border-none rounded-lg py-3 px-6"
                    />
                    </div>
                </div>
            </div>



        </section>
    )
}

export default Footer