import axios from 'axios';

const GeMetricUsage = async () => {
    console.log('response loading...')

    try {
        const response = await axios.get('http://localhost:8080/api/stats/usage');
        return response.data;
    } catch (error) {
        return [];
    }
}

export {
    GeMetricUsage
}