import { useState } from "react";
import { GeMetricUsage } from "repositories/StatsRepository"

const MetricsUsage = () => {
    const [metricData, setMetricData] = useState([]);
    const [metricLoading, setMetricLoading] = useState(true);

    const reloadMetrics = () => {

        GeMetricUsage().then((e) => {

            setMetricData(e);
            setMetricLoading(false);

        });
        
    };

    return {
        metricData,
        metricLoading,
        reloadMetrics
    }
}

export default MetricsUsage;