import React, {useEffect} from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import faviconIcon from 'assets/favicon.jpg';
import ArticleCard from "components/ArticleCard";
import {useDocumentTitle} from "hooks/DocumentTitleHook"


import { ListArticleHook } from "hooks/ArticleHook";

const Blog = () => {

    const { listArticleData, listArticleLoading, getArticleList } = ListArticleHook();
    const [document_title, setDoucmentTitle] = useDocumentTitle("TapeToes");

    useEffect(() => {
        getArticleList(null);
      }, []);

      console.log(listArticleData)

    return (
        <div>
            
            < Navigation />

            <div className="relative py-10">
            <div className="w-full p-12 bg-white">
            <div className="flex items-end justify-between mb-12 header">
                <div className="title px-12">
                    <p className="mb-4 text-4xl font-bold text-gray-800">
                        Lastest articles
                    </p>
                    <p className="text-2xl font-light text-gray-400">
                        All article are verified by 2 experts and valdiate by the CTO
                    </p>
                </div>
                <div className="text-end">
                    <form className="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                        <div className=" relative ">
                            <input type="text" id="&quot;form-subscribe-Search" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Enter a title"/>
                            </div>
                            <button className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
                                Search
                            </button>
                        </form>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-12 md:grid-cols-3 xl:grid-cols-3">

                {
                    listArticleData.data?.articles?.map( (article, idx) => (
                        <ArticleCard  article={article} />
                    ))
                }
                    
                </div>
            </div>

            <div className="flex items-center justify-center mb-12">
    
            <Link to="#" className="px-4 py-2 mx-1 text-gray-500 capitalize bg-white rounded-md cursor-not-allowed dark:bg-gray-800 dark:text-gray-600">
                <div className="flex items-center -mx-1">
                    <span className="mx-1">
                        More
                    </span>
                </div>
            </Link>
        
            </div>

            </div>

            <Footer />

        </div>
    )
}

 export default Blog