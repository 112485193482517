import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Post from './pages/Post';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <div className="App">
       <Routes>
         <Route path='/' element={<Home/>} />
         <Route path='/blog' element={<Blog/>} />
         <Route path='/post/:itemId' element={<Post/>} />
         <Route path='/terms' element={<Terms/>} />
         <Route path='/privacy' element={<Privacy/>} />
       </Routes>
     </div>
    </BrowserRouter>
  </React.StrictMode>
);