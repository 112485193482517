import React, {useEffect} from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { useParams } from 'react-router-dom'
import ArticleRelatedCard from 'components/ArticleRelatedCard';
import { GetArticleHook, RelatedArticleHook } from 'hooks/ArticleHook';
import moment from "moment";
import {useDocumentTitle} from "hooks/DocumentTitleHook"


const Post = (id) => {
    const { itemId } = useParams();
    const {articleData, articleLoading, getArticle} = GetArticleHook();
    const {relatedArticleData, relatedArticleLoading, getRelated} = RelatedArticleHook();
    const [document_title, setDoucmentTitle] = useDocumentTitle("TapeToes");


    useEffect(() => {
        getArticle(itemId);
        getRelated(5);
    }, []);
    
    return <div>
        
        < Navigation />

        <div className="px-20 py-20 flex flex-row">    
            <div className="basis-2/3 px-5">
                <div className="pb-5">
                    <h2 className="text-2xl py-2">{ articleData.data?.article?.title }</h2>
                    <p className=""> { moment(articleData.data?.article?.create_time).format("YYYY-MM-DD") } by <u><i>{articleData.data?.article?.author_name} </i></u></p>
                </div>
                <div>
                    <div className="[&>p]:text-base [&>h2]:text-2xl [&>h2]:py-5 [&>h4]:text-xl [&>h4]:py-3" dangerouslySetInnerHTML={{ __html: articleData.data?.article?.content }} />
                </div>

            </div>
            <div className="basis-1/3">
                {
                    relatedArticleData?.data?.articles?.map( (article, idx) => (
                        <ArticleRelatedCard  article={article}  key={article.id}/>
                    ))
                }
            </div>
        </div>

        <Footer />

    </div>
}

export default Post