import { useState } from "react";
import { GetPopularArticles, GetArticles, GetArticle, GetRelatedArticles } from "repositories/ArticleRepository"

const PopularArticleHook = () => {
    const [popularArticleData, setPopularArticleData] = useState([]);
    const [metricLoading, setMetricLoading] = useState(true);

    const getPopular = (itemSize) => {

        GetPopularArticles(itemSize).then((e) => {

            setPopularArticleData(e);
            setMetricLoading(false);

        });
        
    };

    return {
        popularArticleData,
        metricLoading,
        getPopular
    }
}

const RelatedArticleHook = () => {
    const [relatedArticleData, setRelatedArticleData] = useState([]);
    const [relatedArticleLoading, setRelatedArticleLoading] = useState(true);

    const getRelated = (itemSize) => {

        GetRelatedArticles(itemSize).then((e) => {

            setRelatedArticleData(e);
            setRelatedArticleLoading(false);

        });
        
    };

    return {
        relatedArticleData,
        relatedArticleLoading,
        getRelated
    }
}

const ListArticleHook = () => {
    const [listArticleData, setListArticleData] = useState([]);
    const [listArticleLoading, setListArticleLoading] = useState(true);

    const getArticleList = (page) => {

        GetArticles(page).then((e) => {

            setListArticleData(e);
            setListArticleLoading(false);

        });
        
    };

    return {
        listArticleData,
        listArticleLoading,
        getArticleList
    }
}

const GetArticleHook = () => {
    const [articleData, setArticleData] = useState([]);
    const [articleLoading, setArticleLoading] = useState(true);

    const getArticle = (id) => {

        GetArticle(id).then((e) => {
            setArticleData(e);
            setArticleLoading(false);

        });
        
    };

    return {
        articleData,
        articleLoading,
        getArticle
    }
}

export {
    PopularArticleHook,
    ListArticleHook,
    GetArticleHook,
    RelatedArticleHook
}