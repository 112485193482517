import axios from 'axios';

const GetPopularArticles = async (amount) => {

    try {
        const response = await axios.get('http://localhost:8080/api/articles/popular?size=' + amount);
        return response.data;
    } catch (error) {
        return [];
    }
}


const GetRelatedArticles = async (amount) => {

    try {
        const response = await axios.get('http://localhost:8080/api/articles/popular?size=' + amount);
        return response.data;
    } catch (error) {
        return [];
    }
}

const GetArticles = async (page) => {

    try {
        let url = 'http://localhost:8080/api/articles';

        if (page != null) {
            url += '?page=' + page;
        }
    
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        return [];
    }
}

const GetArticle = async (id) => {

    try {
        let url = 'http://localhost:8080/api/articles/' + id;
    
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        return [];
    }
}

export {
    GetPopularArticles,
    GetArticles,
    GetArticle,
    GetRelatedArticles
}