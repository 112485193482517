import {Link} from "react-router-dom";
import moment from "moment";

const ArticleRelatedCard = ( {article = {}} ) => {

  return (
    <Link to={`/post/${article.id}`}>
    <article className="flex bg-white transition hover:shadow-xl py-1">
        <div className="rotate-180 p-2 [writing-mode:_vertical-lr]">
            <time dateTime="2022-10-10" className="flex items-center justify-between gap-3 text-xs font-bold uppercase text-gray-900">
                <span>{moment(article.create_time).format('YYYY')}</span>
                <span className="w-px flex-1 bg-gray-900/10"></span>
                <span>{moment(article.create_time).format('MMM DD')}</span>
            </time>
        </div>

        <div className="hidden sm:block sm:basis-10">
            <img
            alt=""
            src={article.img_url}
            className="aspect-square h-full w-full object-cover"
            />
        </div>

        <div className="flex flex-1 flex-col justify-between">
            <div className="border-s border-gray-900/10 p-4 sm:border-l-transparent sm:p-6">
            
                <h3 className="font-bold uppercase text-gray-900">
                {article.title}
                </h3>

            <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-700">
                {article.description}
            </p>
            </div>

        </div>
    </article>
    </Link>
  );
}

export default ArticleRelatedCard;