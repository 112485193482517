import React, {useState, useEffect} from "react";
import Navigation from "components/Navigation";
import Footer from "components/Footer";
import BlogSection from "components/BlogSection11";
import backgroundimg from 'assets/background.jpg';

import airpotimg from 'assets/airport.jpeg';
import phoneApp from 'assets/app_iphone_01.png';

import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

import MetricsUsage from "hooks/StatsHook";
import { PopularArticleHook } from "hooks/ArticleHook";
import {useDocumentTitle} from "hooks/DocumentTitleHook"


const APKUrl = "https://play.google.com/store/apps/details?id=host";

function Home() {

  const { metricData, metricLoading, reloadMetrics } = MetricsUsage()
  const { popularArticleData, popularArticleLoading, getPopular } = PopularArticleHook();
  const [document_title, setDoucmentTitle] = useDocumentTitle("TapeToes");

  useEffect(() => {

    getPopular(3);
    reloadMetrics();
    
  }, []);

  console.log(popularArticleData, 'popularArticleData');

  return (
    <div>
     < Navigation />
     <div className="relative">

       {/* background image */}
       <div
          className="fixed inset-0 z-0 "
          style={{
            backgroundImage: `url(${backgroundimg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>


         {/* section starts here */}
         <section className="relative mb-16 z-0 py-8">
          <div className="grid container mx-auto pt-20 p-8 grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
            <div className="mx-auto max-w-lg lg:mx-0 ltr:lg:text-left rtl:lg:text-right rounded-lg">
              <h2 className="text-3xl font-bold sm:text-4xl text-white">TapeToes</h2>
              <p className="mt-4 text-white">
                Everything about BJJ in one place! Articles, Athletes, Videos, and much more...
                We are improving! Download our APP and stay tunned.
              </p>

              <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 py-8 text-center">
                  <GooglePlayButton url={APKUrl} theme={"light"} className={"custom-style"} />
                  <AppStoreButton url={APKUrl} theme={"dark"} className={"custom-style"} />
              </div>

            </div>

            <div className=" sm:grid-cols-1">
              <img className='h-auto max-w-full rounded-lg' src={phoneApp} alt="phone app" />
            </div>
          </div>
        </section>
        {/* section ends here */}


          {/* section starts here */}
        <section className="relative pb-2">

        <div className='w-full h-[700px] bg-gray-900/90 absolute'>
            <img className='w-full h-full object-cover mix-blend-overlay' src={airpotimg} alt="airpotimg" />
        </div>


        <div className='max-w-[1240px] mx-auto text-white relative'>
        <div className="relative  mx-4 -top-20 bg-white rounded-lg shadow-md">
          <div className="flex px-8 pt-1">
          <div className="grid mx-auto py-4 grid-cols-1 gap-y-8 lg:grid-cols-4 lg:items-center lg:gap-x-40">
            
                <div className="flex flex-col rounded-lg px-4 py-8 text-center">
                  <dt className="order-last text-lg font-medium text-gray-500">
                    Users
                  </dt>
                  <dd className="text-4xl font-extrabold mb-4 text-emerald-600 md:text-5xl text-gray-500">
                    {!metricLoading ? metricData?.users : "-"}
                  </dd>
                </div>

                <div className="flex flex-col rounded-lg px-4 py-8 text-center">
                  <dt className="order-last text-lg font-medium text-gray-500">
                    Articles
                  </dt>
                  <dd className="text-4xl font-extrabold mb-4 text-emerald-600 md:text-5xl text-gray-500">
                  {!metricLoading ? metricData?.articles : "-"}
                  </dd>
                </div>

                <div className="flex flex-col rounded-lg px-4 py-8 text-center">
                  <dt className="order-last text-lg font-medium text-gray-500">
                    Tecniques
                  </dt>
                  <dd className="text-4xl font-extrabold mb-4 text-emerald-600 md:text-5xl text-gray-500">
                    {!metricLoading ? metricData?.tecniques : "-"}
                  </dd>
                </div>

                <div className="flex flex-col rounded-lg px-4 py-8 text-center">
                  <dt className="order-last text-lg font-medium text-gray-500">
                    Events
                  </dt>
                  <dd className="text-4xl font-extrabold mb-4 text-emerald-600 md:text-5xl text-gray-500">
                  {!metricLoading ? metricData?.events : "-"}
                  </dd>
                </div>

          </div>
          </div>
        </div>

        <div className='px-4 py-12 md:block hidden'>
              <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'>Keep Training</h2>
              <h3 className='text-5xl font-bold py-6 text-center'>The Best Way For You</h3>
              <p className='py-4 text-3xl text-slate-300 text-center'>
                The main goal is to help people improve their skills, connect to friends, and be able to get closer and closer to <u>Brazilian Jiu Jitsu</u>.
              </p>
        </div>

        </div>


        </section>
        {/* section ends here */}


  <section className="relative ">
    <BlogSection articles={popularArticleData?.data?.articles} />
  </section>

  {/* Section starts here */}
  <Footer />
  {/*section ends here*/}
     </div>

      
    </div>
  );
}

export default Home;