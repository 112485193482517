import React, { useEffect, useState } from "react";
import faviconIcon from 'assets/favicon.jpg';

const useDocumentTitle = title => {

    const [document_title, setDoucmentTitle] = useState(title);

    useEffect(() => {
        document.title = document_title; 

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = faviconIcon;
    },[document_title]);

    return [document_title, setDoucmentTitle];
};

export {useDocumentTitle};