import React, {useEffect} from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {useDocumentTitle} from "hooks/DocumentTitleHook"

const Privacy  = () => {
    const [document_title, setDoucmentTitle] = useDocumentTitle("TapeToes");
    
    return (
        <div>
            <Navigation />
            <Footer />
        </div>
    )
}

export default Privacy