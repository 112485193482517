import {Link} from "react-router-dom";
import moment from "moment";
import ArticleCard from "./ArticleCard";

const BlogSection11 = ( {articles = []} ) => {

  return (
    
      
<div className="w-full p-12 bg-white">
    <div className="flex items-end justify-between mb-12 header">
        <div className="title">
            <p className="mb-4 text-4xl font-bold text-gray-800">
                Latest Articles
            </p>
            <p className="text-2xl font-light text-gray-400">
                Last Articles about BJJ Culture and Skills
            </p>
        </div>
    </div>
    <div className="grid grid-cols-1 gap-12 md:grid-cols-3 xl:grid-cols-3">
        {
            articles.map( (article, idx) => (
                <ArticleCard  article={article} />
            ))
        }

    </div>
</div> 
  );
}

export default BlogSection11;