import {Link} from "react-router-dom";
import moment from "moment";

const ArticleCard = ( {article = {}} ) => {

  return (
    <div className="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80" key={article.id}>
        <Link to={ `/post/${article.id}` } className="block w-full h-full">
            <img alt="blog photo" src={article.img_url} className="object-cover w-full max-h-40"/>
            <div className="w-full p-4 bg-white dark:bg-gray-800">
                <p className="font-medium text-indigo-500 text-md">
                    Video
                </p>
                <p className="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                    {article.title}
                </p>
                <p className="font-light text-gray-400 dark:text-gray-300 text-md">
                    {article.description}
                </p>
                <div className="flex items-center mt-4">
                    {/* <a href="#" className="relative block"> */}
                        <img alt="profil" src={article.author_img} className="mx-auto object-cover rounded-full h-10 w-10 "/>
                    {/* </a> */}
                    <div className="flex flex-col justify-between ml-4 text-sm">
                        <p className="text-gray-800 dark:text-white">
                            {article.author_name}
                        </p>
                        <p className="text-gray-400 dark:text-gray-300">
                            { moment(article.create_time).format("YYYY-MM-DD") }  - {article.views} views
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    </div>
  );
}

export default ArticleCard;